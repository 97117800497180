import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import SelectorField from "../../../components/SelectorField"
import FullWorkOrderTemplate from "../../../queries/fragments/workOrderTemplate"
import { useAuth } from "../../../context/AuthContext"
import { WorkOrderTemplate } from "../../../types"

const ALL_WORK_ORDER_TEMPLATES = gql`
  query GetOrganizationById($id: ID!) {
    getOrganizationById(id: $id) {
      id
      workOrderTemplates {
        ...FullWorkOrderTemplate
      }
    }
  }
  ${FullWorkOrderTemplate}
`

interface Props {
  readonly onCancel: () => void
  readonly onSelectTemplate: (template: WorkOrderTemplate) => void
}

function LoadWorkOrderFromTemplate({ onCancel, onSelectTemplate }: Props) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [selectedTemplate, setSelectedTemplate] = useState<WorkOrderTemplate | null>(null)

  const { data: allWorkOrderTemplatesData, loading } = useQuery(ALL_WORK_ORDER_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    skip: !user?.organization?.id,
    variables: {
      id: user?.organization?.id,
    },
    onCompleted: (data) => {
      if (!selectedTemplate && data?.getOrganizationById?.workOrderTemplates?.length >= 1) {
        const template = data.getOrganizationById.workOrderTemplates[0]
        setSelectedTemplate(template)
      }
    },
  })

  const templates = allWorkOrderTemplatesData?.getOrganizationById?.workOrderTemplates || []

  function handleSelect() {
    selectedTemplate && onSelectTemplate(selectedTemplate)
  }

  return (
    <Dialog
      aria-labelledby="load-workorder-from-template-dialog"
      data-testid="LoadWorkOrderFromTemplate"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {t("component.loadWorkOrderFromTemplateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            color: (theme) => theme.fielderColors.mutedText,
            paddingBottom: 0,
            paddingTop: "1rem",
            px: "1rem",
          }}
        >
          {t("component.loadWorkOrderFromTemplateDialog.description")}
        </Box>
        <Box sx={classes.fieldContainer}>
          {templates.length === 0 ? (
            <Box
              sx={(theme) => ({
                color: theme.fielderColors.mutedText,
                border: `1px solid ${theme.fielderColors.mutedText}`,
                borderRadius: "4px",
                padding: "0.625rem",
                backgroundColor: "#fff",
              })}
            >
              {t("component.loadWorkOrderFromTemplateDialog.noTemplates")}
            </Box>
          ) : (
            <SelectorField
              label={t("selectTemplate") as string}
              name="template"
              onChange={(selection) => {
                const template = templates?.find((t) => t.id === selection.id)
                setSelectedTemplate(template)
              }}
              options={templates}
              value={selectedTemplate?.id ?? ""}
              variant="outlined"
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedTemplate}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("loadFromTemplate")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const classes = {
  fieldContainer: {
    marginTop: "1.25rem",
  },
} as const

export default LoadWorkOrderFromTemplate
