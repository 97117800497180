import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CircularProgress from "@mui/material/CircularProgress"
import SelectorField from "../SelectorField"

const ALL_CHECKLIST_TEMPLATES = gql`
  query AllChecklistTemplates {
    allChecklistTemplates {
      edges {
        node {
          id
          type
          name
          diagram
          lineItems {
            id
            diagramId
            number
            requiredCompressionCapacity
            requiredTorque
            pileUsed
            helixUsed
            totalPileLength
            cutOffElevation
            pileInclination
          }
        }
      }
    }
  }
`

interface LoadFromTemplateProps {
  onCancel: () => void
  onSelectTemplate: (template) => void
}

function LoadFromTemplate({ onCancel, onSelectTemplate }: LoadFromTemplateProps) {
  const { t } = useTranslation()
  const [selectedTemplate, setSelectedTemplate] = useState()

  const { data, loading } = useQuery(ALL_CHECKLIST_TEMPLATES, {
    displayName: "AllChecklistTemplates",
    fetchPolicy: "cache-and-network",
  })

  const templates = data?.allChecklistTemplates?.edges?.map((e) => e.node) || []

  function handleSelect() {
    onSelectTemplate?.(selectedTemplate)
  }

  return (
    <Dialog
      aria-labelledby="load-from-template-dialog"
      data-testid="LoadFromTemplate"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open
    >
      <DialogTitle
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {t("component.loadChecklistFromTemplateDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingBottom: 0,
            paddingTop: "0.5rem",
          }}
        >
          <Box
            sx={{
              color: (theme) => theme.fielderColors.mutedText,
              marginTop: "0.625rem",
              marginBottom: "0.625rem",
            }}
          >
            {t("component.loadChecklistFromTemplateDialog.description")}
          </Box>
          <Box
            sx={{
              marginTop: "1.25rem",
            }}
          >
            {templates.length === 0 ? (
              <Box
                sx={(theme) => ({
                  color: theme.fielderColors.mutedText,
                  border: `1px solid ${theme.fielderColors.mutedText}`,
                  borderRadius: "4px",
                  padding: "0.625rem",
                  backgroundColor: "#fff",
                })}
              >
                {t("component.loadChecklistFromTemplateDialog.noTemplates")}
              </Box>
            ) : (
              <SelectorField
                label={t("selectTemplate") as string}
                name="template"
                onChange={(selection) => {
                  const template = templates?.find((t) => t.id === selection.id)
                  setSelectedTemplate(template)
                }}
                options={templates}
                value={selectedTemplate?.id}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: "1.5rem",
          paddingBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button color="secondary" disabled={loading} onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="loadButton"
          disabled={loading || !selectedTemplate}
          onClick={handleSelect}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={20} thickness={6.0} />
          ) : (
            <Box>{t("loadFromTemplate")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoadFromTemplate
