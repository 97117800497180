import gql from "graphql-tag"

export const CREATE_CHECKLIST_LINE_ITEM_NOTE = gql`
  mutation CreateChecklistLineItemNote(
    $checklistLineItemId: ID!
    $text: String!
  ) {
    createChecklistLineItemNote(
      input: { checklistLineItemId: $checklistLineItemId, text: $text }
    ) {
      checklistLineItemNote {
        id
        text
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`
