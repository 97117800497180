import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import * as Sentry from "@sentry/react"
import { capitalize } from "lodash"
import { nanoid } from "nanoid"
import { useMutation, useQuery } from "@apollo/client"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import Paper from "@mui/material/Paper"
import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"

import LoadWorkOrderFromTemplateDialog from "./LoadWorkOrderFromTemplateDialog"
import SaveAsWorkOrderTemplateDialog from "./SaveAsWorkOrderTemplateDialog"
import { CREATE_WORK_ORDER } from "~/queries/createWorkOrder"
import { EDIT_WORK_ORDER } from "~/queries/editWorkOrder"
import { CREATE_ESTIMATE } from "~/queries/createEstimate"
import { CREATE_INVOICE } from "~/queries/createInvoice"
import { GET_WORK_ORDER_BY_ID } from "~/queries/getWorkOrderById"
import {
  isBlank,
  parseGraphQLErrorCode,
  updateTransactionLineItem,
  createTransactionLineItemFormInput,
  formatPersonName,
  formatAddress,
  getJobDocumentDisplayNumber,
  formatDate,
  asFloat,
  asInt,
  isNumeric,
  isEmpty,
  calculateLineTotal,
  calculateSubtotal,
  calculateTotal,
  convertToTransactionLineItemInput,
} from "~/util"
import FielderTextField from "~/components/FielderTextField"
import LineItem from "~/components/LineItem"
import SnackbarMessage from "~/components/SnackbarMessage"
import LoadFromEstimateDialog from "./LoadFromEstimateDialog"
import ChangeJobAssignmentDialog from "./ChangeJobAssignmentDialog"
import {
  JobAssignment,
  Snack,
  User,
  DefaultPermission,
  WorkOrderLineItem,
  EstimateStatus,
  Estimate,
  Invoice,
  InvoiceStatus,
  WorkOrderTemplateLineItem,
  EstimateLineItem,
  WorkOrderLineItemDetail,
  NetTerms,
  CurrencyCode,
  Job,
  WorkOrderFormInput,
  TransactionLineItemFormInput,
  DiscountType,
} from "~/types"
import SaveButton from "~/components/SaveButton"
import { useAuth } from "~/context/AuthContext"
import GenerateEstimateDialog from "./GenerateEstimateDialog"
import GenerateInvoiceDialog from "./GenerateInvoiceDialog"
import Spinner from "~/components/Spinner"
import useGetUserOrganizationPreferences from "~/hooks/useGetUserOrganizationPreferences"
import FielderIconButton from "~/components/FielderIconButton"

const dialogBackgroundColor = "#FFFFFF"

function createNewWorkOrder(job: Job, currencyCode: CurrencyCode): WorkOrderFormInput {
  return {
    jobAddress: job.address?.addressString ?? "",
    description: job.description ?? "",
    notes: "",
    currencyCode: currencyCode ?? "USD",
    lineItems: [
      {
        number: 1,
        key: nanoid(),
        unitPrice: "",
        quantity: "",
        organizationItemId: "",
      },
    ],
    subTotal: 0,
    total: 0,
    job,
  }
}

function getRowStyle(isDragging: boolean, draggableStyle: any) {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#f1f8ff" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  }
}

function getListStyle(isDraggingOver: boolean) {
  return {
    background: isDraggingOver ? "#efefef" : "#fff",
  }
}

interface Props {
  readonly open?: boolean
  readonly onCancel: () => void
  readonly onSave: (workOrder: WorkOrderFormInput) => void
  readonly job: Job
  readonly workOrderId?: string
  readonly user: User
}

function WorkOrderDialog({ open, onCancel, onSave, job, workOrderId, user }: Props) {
  const { t } = useTranslation()
  const organizationPreferences = useGetUserOrganizationPreferences(user.organization.id)
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >()
  const showMoreActionsMenu = Boolean(moreActionsMenuAnchorEl)
  const { hasPermissions } = useAuth()
  const [snack, setSnack] = useState<Snack>()
  const [activeWorkOrder, setActiveWorkOrder] = useState<WorkOrderFormInput>(() => {
    return createNewWorkOrder(job, user.organization.currencyCode)
  })
  const [generateEstimateDialogOpen, setGenerateEstimateDialogOpen] = useState<boolean>(false)
  const [generatedEstimate, setGeneratedEstimate] = useState<Estimate | null>(null)
  const [generateInvoiceDialogOpen, setGenerateInvoiceDialogOpen] = useState<boolean>(false)
  const [generatedInvoice, setGeneratedInvoice] = useState<Invoice | null>(null)
  const [loadFromEstimateDialogOpen, setLoadFromEstimateDialogOpen] = useState<boolean>(false)
  const [changeJobAssignmentDialogOpen, setChangeJobAssignmentDialogOpen] = useState<boolean>(false)
  const [loadFromTemplateDialogOpen, setLoadFromTemplateDialogOpen] = useState<boolean>(false)
  const [saveAsTemplateDialogOpen, setSaveAsTemplateDialogOpen] = useState<boolean>(false)
  const [assignment, setAssignment] = useState<JobAssignment | null>(null)
  const [description, setDescription] = useState<string>("")
  const [notes, setNotes] = useState<string>("")
  const [lineItems, setLineItems] = useState<TransactionLineItemFormInput[]>(() => {
    return [
      {
        number: 1,
        key: nanoid(),
        unitPrice: "",
        quantity: "",
        organizationItemId: "",
      } as TransactionLineItemFormInput,
    ]
  })
  const [errors, setErrors] = useState<{
    lineItems: { title: string; message: string } | null
  } | null>(() => ({
    lineItems: null,
  }))
  const canEdit = hasPermissions ? hasPermissions([DefaultPermission.UpdateWorkOrder]) : false

  const { loading: getWorkOrderLoading } = useQuery(GET_WORK_ORDER_BY_ID, {
    variables: {
      id: workOrderId,
    },
    skip: !workOrderId,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const workOrder = data.getWorkOrderById
      const editableLineItems = workOrder.lineItems
        .map((li: WorkOrderLineItem) => {
          return {
            ...li,
            organizationItemId: li.organizationItem.id,
            key: li.id,
            description: li.description ?? "",
            quantity: !isEmpty(li.quantity) ? `${li.quantity}` : "",
            unitPrice: !isEmpty(li.unitPrice) ? `${li.unitPrice}` : "",
            total: li.total,
            taxRateGroup: li.taxRateGroup,
            taxRateGroupId: li.taxRateGroup?.id,
            showDetails: li.showDetails,
            lineItemDetails: li.lineItemDetails?.map(
              (d: WorkOrderLineItemDetail, index: number) => ({
                id: d.id,
                organizationItem: d.organizationItem,
                organizationItemId: d.organizationItem.id,
                quantity: asInt(d.quantity),
                unitPrice: asFloat(d.unitPrice),
                number: d.number > 0 ? d.number : index + 1,
              })
            ),
          }
        })
        .sort((a: WorkOrderLineItem, b: WorkOrderLineItem) => a.number - b.number)

      const editableWorkOrder = {
        id: workOrder.id,
        job: workOrder.job,
        jobAssignment: workOrder.jobAssignment,
        number: workOrder.number,
        currencyCode: workOrder.currencyCode ?? user?.organization?.currencyCode,
        subTotal: calculateSubtotal(workOrder.lineItems),
        total: calculateTotal(workOrder.lineItems, 0, DiscountType.PERCENTAGE),
        createdAt: workOrder.createdAt,
        description: workOrder.description,
        notes: workOrder.notes,
        lineItems: editableLineItems,
      } as WorkOrderFormInput

      setActiveWorkOrder(editableWorkOrder)
      setAssignment(workOrder.jobAssignment ?? null)
      setDescription(workOrder.description ?? "")
      setNotes(workOrder.notes ?? "")
      setLineItems(editableLineItems)
    },
  })

  const [editWorkOrder, { loading: editWorkOrderLoading, error: editWorkOrderError }] = useMutation(
    EDIT_WORK_ORDER,
    {
      onCompleted: (data) => {
        onSave?.(data.editWorkOrder.workOrder)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
    }
  )

  const [createWorkOrder, { loading: createWorkOrderLoading, error: createWorkOrderError }] =
    useMutation(CREATE_WORK_ORDER, {
      onCompleted: (data) => {
        onSave?.(data.createWorkOrder.workOrder)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
      refetchQueries: () => {
        return ["AllWorkOrdersForJob"]
      },
    })

  const [createEstimate, { loading: createEstimateLoading, error: createEstimateError }] =
    useMutation(CREATE_ESTIMATE, {
      onCompleted: (data) => {
        setGeneratedEstimate(data.createEstimate.estimate)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
    })

  const [createInvoice, { loading: createInvoiceLoading, error: createInvoiceError }] = useMutation(
    CREATE_INVOICE,
    {
      onCompleted: (data) => {
        setGeneratedInvoice(data.createInvoice.invoice)
      },
      onError: (error) => {
        Sentry.captureException(error)
      },
    }
  )

  useEffect(() => {
    if (createEstimateLoading) {
      setGenerateEstimateDialogOpen(true)
    }
  }, [createEstimateLoading])

  useEffect(() => {
    if (createInvoiceLoading) {
      setGenerateInvoiceDialogOpen(true)
    }
  }, [createInvoiceLoading])

  const networkError = editWorkOrderError || createWorkOrderError
  const loading = editWorkOrderLoading || createWorkOrderLoading
  const editMode = Boolean(activeWorkOrder?.id)

  function addLineItem() {
    setLineItems(lineItems.concat([createTransactionLineItemFormInput(lineItems.length + 1)]))
  }

  function updateLineItem(reason: string, payload: any) {
    const updatedLineItems = updateTransactionLineItem(reason, payload, lineItems)

    if (areAllLineItemsValid(updatedLineItems)) {
      setErrors({
        ...errors,
        lineItems: null,
      })
    }

    setLineItems(updatedLineItems)
  }

  function removeLineItem(lineItem: TransactionLineItemFormInput) {
    const updatedLineItems = lineItems.filter((li) => li.number !== lineItem.number)
    updatedLineItems.forEach((item, idx) => {
      item.number = idx + 1
    })
    if (areAllLineItemsValid(updatedLineItems)) {
      setErrors({
        ...errors,
        lineItems: null,
      })
    }
    setLineItems(updatedLineItems)
  }

  function areAllLineItemsValid(lines: TransactionLineItemFormInput[]): boolean {
    const hasInvalidLineItem = lines.some((li) => {
      return isBlank(li.organizationItemId)
    })

    return !hasInvalidLineItem
  }

  function handleRowDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const startIndex = result.source.index
    const endIndex = result.destination.index
    if (startIndex === endIndex) {
      return
    }

    const sourceLineItem = lineItems[startIndex]

    let sortedLines = Array.from(lineItems)
    sortedLines.splice(startIndex, 1)
    sortedLines.splice(endIndex, 0, sourceLineItem)
    sortedLines = sortedLines.map((item, idx) => ({
      ...item,
      number: idx + 1,
    }))

    setLineItems(sortedLines)
  }

  function handleSave(extraOptions?: { onCompleted: (data: any) => void }) {
    if (!activeWorkOrder) {
      return
    }

    const nonEmptyLineItems = lineItems.filter((li) => Boolean(li.organizationItemId))

    if (!nonEmptyLineItems || nonEmptyLineItems.length === 0) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.workOrderDialog.lineItems.required.title"),
          message: t("component.workOrderDialog.lineItems.required.message"),
        },
      })
      return
    }

    if (!areAllLineItemsValid(nonEmptyLineItems)) {
      setErrors({
        ...errors,
        lineItems: {
          title: t("component.workOrderDialog.lineItems.invalid.title"),
          message: t("component.workOrderDialog.lineItems.invalid.message"),
        },
      })
      const validatedLineItems = nonEmptyLineItems.map((li) => {
        return {
          ...li,
          errors: {
            showErrors: true,
            organizationItemId: !li.organizationItemId ? "required" : null,
          },
        }
      })
      setLineItems(validatedLineItems)
      return
    }

    setErrors(null)

    const variables = {
      id: activeWorkOrder.id,
      jobId: activeWorkOrder.job?.id,
      jobAssignmentId: assignment?.id ?? null,
      description: description.trim().substring(0, 1000),
      notes: notes.trim().substring(0, 1000),
      billingContact: activeWorkOrder.billingContact
        ? {
            id: activeWorkOrder.billingContact.id,
          }
        : undefined,
      discount: activeWorkOrder.discount
        ? {
            value: activeWorkOrder.discount?.value,
            type: activeWorkOrder.discount?.type,
          }
        : undefined,
      lineItems: convertToTransactionLineItemInput(nonEmptyLineItems, false, false).map((li) => ({
        ...li,
      })),
    }

    editMode
      ? editWorkOrder({ variables, ...extraOptions })
      : createWorkOrder({ variables, ...extraOptions })
  }

  function generateEstimate() {
    setMoreActionsMenuAnchorEl(undefined)

    handleSave({
      onCompleted: (data) => {
        const savedWorkOrder = editMode
          ? data.editWorkOrder.workOrder
          : data.createWorkOrder.workOrder

        setActiveWorkOrder(savedWorkOrder)

        const sanitizedLineItems = savedWorkOrder.lineItems
          .filter((li: WorkOrderLineItem) => isNumeric(li.quantity))
          .map((li: WorkOrderLineItem) => {
            const lineItem = {
              number: li.number,
              organizationItemId: li.organizationItem.id,
              description: li.description,
              quantity: asInt(li.quantity ?? 0),
              unitPrice: asFloat(li.unitPrice ?? 0),
              taxRateGroupId: li.taxRateGroup?.id,
              showDetails: li.showDetails,
              lineItemDetails: li.lineItemDetails?.map(
                (d: WorkOrderLineItemDetail, index: number) => ({
                  id: d.id,
                  organizationItemId: d.organizationItem.id,
                  quantity: asInt(d.quantity),
                  unitPrice: asFloat(d.unitPrice),
                  number: d.number > 0 ? d.number : index + 1,
                })
              ),
            }
            return lineItem
          })
          .sort((a: WorkOrderLineItem, b: WorkOrderLineItem) => {
            return (a.number ?? 0) - (b.number ?? 0)
          })
          .map((li: WorkOrderLineItem, index: number) => ({
            ...li,
            number: index + 1,
          }))

        const variables = {
          jobId: savedWorkOrder.job.id,
          billingContact: savedWorkOrder.billingContact
            ? {
                id: savedWorkOrder.billingContact.id,
              }
            : undefined,
          description: savedWorkOrder.description?.trim()?.substring(0, 1000) ?? "",
          notes: savedWorkOrder.notes?.trim()?.substring(0, 1000),
          expirationDate: undefined,
          discount: savedWorkOrder.discount
            ? {
                value: savedWorkOrder.discount?.value,
                type: savedWorkOrder.discount?.type,
              }
            : undefined,
          lineItems: sanitizedLineItems,
          status: EstimateStatus.PENDING_SUBMISSION,
          footerTitle: undefined,
          footerBody: undefined,
        }

        createEstimate({ variables })
      },
    })
  }

  function generateInvoice() {
    setMoreActionsMenuAnchorEl(undefined)
    handleSave({
      onCompleted: (data) => {
        const savedWorkOrder = editMode
          ? data.editWorkOrder.workOrder
          : data.createWorkOrder.workOrder

        const sanitizedLineItems = savedWorkOrder.lineItems
          .filter((li: WorkOrderLineItem) => isNumeric(li.quantity))
          .map((li: WorkOrderLineItem) => {
            const lineItem = {
              number: li.number,
              organizationItemId: li.organizationItem.id,
              description: li.description,
              quantity: asInt(li.quantity ?? 0),
              unitPrice: asFloat(li.unitPrice ?? 0),
              taxRateGroupId: li.taxRateGroup?.id,
              showDetails: li.showDetails,
              lineItemDetails: li.lineItemDetails?.map(
                (d: WorkOrderLineItemDetail, index: number) => ({
                  id: d.id,
                  organizationItemId: d.organizationItem.id,
                  quantity: asInt(d.quantity),
                  unitPrice: asFloat(d.unitPrice),
                  number: d.number > 0 ? d.number : index + 1,
                })
              ),
            }
            return lineItem
          })
          .sort((a: WorkOrderLineItem, b: WorkOrderLineItem) => {
            return (a.number ?? 0) - (b.number ?? 0)
          })
          .map((li: WorkOrderLineItem, index: number) => ({
            ...li,
            number: index + 1,
          }))

        const variables = {
          jobId: savedWorkOrder.job.id,
          description: savedWorkOrder.description?.trim()?.substring(0, 1000),
          notes: savedWorkOrder.notes?.trim()?.substring(0, 1000),
          issuedDate: null,
          netTerms: NetTerms.NET_30,
          dueDate: null,
          discount: savedWorkOrder.discount
            ? {
                value: savedWorkOrder.discount?.value,
                type: savedWorkOrder.discount?.type,
              }
            : undefined,
          billingContact: savedWorkOrder.billingContact
            ? { id: savedWorkOrder.billingContact.id }
            : undefined,
          lineItems: sanitizedLineItems,
          status: InvoiceStatus.DRAFT,
        }

        createInvoice({ variables })
      },
    })
  }

  const currencyCode = activeWorkOrder?.currencyCode ?? user.organization.currencyCode ?? "USD"

  return (
    <>
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <Dialog
        aria-labelledby="form-dialog-title"
        data-testid="WorkOrderDialog"
        disableEscapeKeyDown={false}
        fullWidth
        maxWidth="xl"
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            onCancel?.()
          }
        }}
        open={Boolean(open)}
        sx={{
          marginBottom: "50px", // make room for the intercom bubble
          "& .MuiDialog-paper": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          sx={{
            py: "0.625rem",
            px: "1.5rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {activeWorkOrder?.id
            ? t("editWorkOrder", { number: getJobDocumentDisplayNumber(activeWorkOrder) })
            : t("createWorkOrder")}
          <FielderIconButton
            aria-label={t("close") as string}
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
            }}
            title={t("close") as string}
          >
            <CancelIcon />
          </FielderIconButton>
        </DialogTitle>
        <DialogContent>
          {getWorkOrderLoading ? (
            <Box>
              <Spinner />
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: dialogBackgroundColor,
                paddingBottom: 0,
                paddingTop: "0.5rem",
                display: "flex",
                flexDirection: "column",
                minHeight: "390px",
              }}
            >
              {networkError ? (
                <Box sx={classes.errorContainer}>
                  <Box sx={classes.errorTitle}>{t("error.general.title")}</Box>
                  <Box sx={classes.errorMessage}>{t(parseGraphQLErrorCode(networkError))}</Box>
                </Box>
              ) : null}
              {!networkError && errors?.lineItems ? (
                <Box sx={classes.errorContainer}>
                  <Box sx={classes.errorTitle}>{errors.lineItems.title}</Box>
                  <Box sx={classes.errorMessage}>{errors.lineItems.message}</Box>
                </Box>
              ) : null}
              {loading ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    flex: 1,
                  }}
                >
                  <CircularProgress />
                  <p>{t("saving")} ...</p>
                </Box>
              ) : (
                <Box
                  sx={{
                    flex: 1,
                    minWidth: "88rem",
                    px: "1rem",
                  }}
                >
                  <Box sx={classes.rowContainer}>
                    <Box sx={classes.rowItem}>
                      <Box component="label" sx={classes.staticFieldLabel}>
                        {t("jobAddress")}
                      </Box>
                      <Box sx={classes.staticFieldValue}>
                        {formatAddress(activeWorkOrder?.job?.address?.addressString)}
                      </Box>
                    </Box>
                    <Box sx={classes.rowItem}>
                      <Box sx={classes.jobAssignmentLabelContainer}>
                        <Box component="label" sx={classes.staticFieldLabel}>
                          {t("jobAssignment")}
                        </Box>
                        {canEdit ? (
                          <>
                            <Box
                              component="span"
                              onClick={() => {
                                setChangeJobAssignmentDialogOpen(true)
                              }}
                              sx={classes.changeJobAssignmentLink}
                            >
                              {t("change")}
                            </Box>
                            {assignment ? (
                              <Box
                                component="span"
                                onClick={() => {
                                  setAssignment(null)
                                }}
                                sx={classes.changeJobAssignmentLink}
                              >
                                {t("remove")}
                              </Box>
                            ) : null}
                          </>
                        ) : null}
                      </Box>
                      {assignment ? (
                        <Box sx={classes.staticFieldValue}>
                          <Box sx={classes.jobAssignmentFieldContainer}>
                            <Box sx={classes.jobAssignmentFieldLabel}>{t("dateTime")}:</Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Box sx={classes.jobAssignmentFieldValue}>
                                {formatDate(
                                  assignment.startDate,
                                  t("format:dateFormat.fullDate"),
                                  user?.organization?.timeZone,
                                  user
                                )}
                              </Box>
                              <Box sx={classes.jobAssignmentFieldValue}>
                                <span>
                                  {formatDate(
                                    assignment.startDate,
                                    t("format:dateFormat.time"),
                                    user?.organization?.timeZone,
                                    user
                                  )}
                                </span>
                                <span>{" - "}</span>
                                <span>
                                  {formatDate(
                                    assignment.endDate,
                                    t("format:dateFormat.time"),
                                    user?.organization?.timeZone,
                                    user
                                  )}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={classes.jobAssignmentFieldContainer}>
                            <Box sx={classes.jobAssignmentFieldLabel}>{t("assignedTo")}:</Box>
                            <Box sx={classes.jobAssignmentFieldValue}>
                              {assignment.assignees?.map((a) => (
                                <Box key={a.id}>{formatPersonName(a)}</Box>
                              ))}
                            </Box>
                          </Box>
                          <Box sx={classes.jobAssignmentFieldContainer}>
                            <Box sx={classes.jobAssignmentFieldLabel}>{t("status")}:</Box>
                            <Box sx={classes.jobAssignmentFieldValue}>
                              {capitalize(assignment.status)}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "0.875rem",
                            fontStyle: "italic",
                            marginTop: "0.5rem",
                            color: (theme) => theme.fielderColors.mutedText,
                          }}
                        >
                          <Box sx={[classes.staticFieldValue]}>
                            {t("component.workOrderDialog.noAssignment")}
                          </Box>
                          <Box sx={[classes.staticFieldValue]}>
                            {t("component.workOrderDialog.oneWorkOrderPerAssignment")}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box sx={classes.rowContainer}>
                    <Box sx={classes.rowItem}>
                      <FormControl fullWidth sx={classes.formControl}>
                        <FielderTextField
                          aria-label={t("description") as string}
                          data-testid="workOrderDialogField_description"
                          helperText={t("component.workOrderDialog.descriptionHelperText")}
                          id="description"
                          inputProps={{ maxLength: 1000 }}
                          label={t("description")}
                          maxRows={4}
                          minRows={4}
                          multiline
                          name="description"
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={
                            t("component.workOrderDialog.descriptionPlaceholder") as string
                          }
                          value={description}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={classes.rowItem}>
                      <FormControl fullWidth sx={classes.formControl}>
                        <FielderTextField
                          aria-label={t("notes") as string}
                          data-testid="workOrderDialogField_notes"
                          helperText={t("component.workOrderDialog.notesHelperText")}
                          id="notes"
                          inputProps={{ maxLength: 1000 }}
                          label={t("notes")}
                          maxRows={4}
                          minRows={4}
                          multiline
                          name="notes"
                          onChange={(e) => setNotes(e.target.value)}
                          placeholder={t("component.workOrderDialog.notesPlaceholder") as string}
                          value={notes}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box sx={classes.rowContainer}>
                    <Box sx={classes.rowItem}>
                      <Paper elevation={0} sx={classes.lineItemTableContainer}>
                        <DragDropContext onDragEnd={handleRowDragEnd}>
                          <Box>
                            <Box sx={[classes.lineItemGridRow, classes.lineItemHeader]}>
                              <Box />
                              <label>{t("item")}</label>
                              <label>{t("description")}</label>
                              <label>{t("quantity")}</label>
                              <Box />
                            </Box>
                          </Box>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {lineItems.map((li, index) => {
                                  return (
                                    <Draggable draggableId={li.key} index={index} key={li.key}>
                                      {(provided, snapshot) => (
                                        <LineItem
                                          allowBundleModifications={Boolean(
                                            organizationPreferences?.allowBundleModsOnTxns
                                          )}
                                          canEdit={canEdit}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          currencyCode={currencyCode}
                                          key={li.key}
                                          lineItem={li}
                                          onChange={updateLineItem}
                                          onDelete={(item) => removeLineItem(item)}
                                          style={{
                                            ...classes.lineItemGridRow,
                                            ...getRowStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            ),
                                          }}
                                          taxRateGroupOptions={[]}
                                          taxRateGroupsOptionsLoading={false}
                                          variant="WORK_ORDER"
                                        />
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          {canEdit ? (
                            <Box sx={classes.addLineCell}>
                              <Button
                                data-testid="invoiceDialogField_addLineItemButton"
                                fullWidth
                                onClick={addLineItem}
                                sx={{
                                  border: "1px solid #288906",
                                  borderRadius: "4px",
                                  color: "#288906",
                                  fontWeight: 600,
                                  "&:hover": {
                                    backgroundColor: "#F5FFF2",
                                  },
                                }}
                                variant="outlined"
                              >
                                <AddIcon />
                                <Box>{t("addALine")}</Box>
                              </Button>
                            </Box>
                          ) : null}
                        </DragDropContext>
                      </Paper>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: dialogBackgroundColor,
            borderTop: "1px solid #cdcdcd",
            padding: "1.5rem",
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={(theme) => {
              return {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                [theme.breakpoints.up("sm")]: {
                  display: "none",
                },
              }
            }}
          >
            <Button
              aria-controls={showMoreActionsMenu ? "more-actions-menu" : undefined}
              aria-expanded={showMoreActionsMenu ? "true" : undefined}
              aria-haspopup="true"
              disableElevation
              disabled={loading}
              endIcon={<KeyboardArrowDownIcon />}
              id="more-actions-menu-button"
              onClick={(event) => {
                setMoreActionsMenuAnchorEl(event.currentTarget)
              }}
              sx={{ width: "100%" }}
              variant="outlined"
            >
              {t("moreActions")}
            </Button>
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Button
              color="secondary"
              data-testid="workOrderDialogField_cancelButton"
              disabled={loading}
              onClick={onCancel}
              variant="outlined"
            >
              {t("cancel")}
            </Button>
            <Button
              aria-controls={showMoreActionsMenu ? "more-actions-menu" : undefined}
              aria-expanded={showMoreActionsMenu ? "true" : undefined}
              aria-haspopup="true"
              disableElevation
              disabled={loading}
              endIcon={<KeyboardArrowDownIcon />}
              id="more-actions-menu-button"
              onClick={(event) => {
                setMoreActionsMenuAnchorEl(event.currentTarget)
              }}
              sx={(theme) => {
                return {
                  display: "none",
                  [theme.breakpoints.up("sm")]: {
                    display: "inline-flex",
                  },
                }
              }}
              variant="text"
            >
              {t("moreActions")}
            </Button>
            {canEdit ? <SaveButton loading={loading} onClick={handleSave} /> : null}
          </Box>
        </DialogActions>
      </Dialog>
      {generateEstimateDialogOpen ? (
        <GenerateEstimateDialog
          error={createEstimateError ? t(parseGraphQLErrorCode(createEstimateError)) : null}
          estimate={generatedEstimate}
          isLoading={createEstimateLoading}
          onClose={() => {
            setGenerateEstimateDialogOpen(false)
            setGeneratedEstimate(null)
          }}
        />
      ) : null}
      {generateInvoiceDialogOpen ? (
        <GenerateInvoiceDialog
          error={createInvoiceError ? t(parseGraphQLErrorCode(createInvoiceError)) : null}
          invoice={generatedInvoice}
          isLoading={createInvoiceLoading}
          onClose={() => {
            setGenerateInvoiceDialogOpen(false)
            setGeneratedInvoice(null)
          }}
        />
      ) : null}
      {loadFromEstimateDialogOpen && activeWorkOrder?.job?.id ? (
        <LoadFromEstimateDialog
          jobId={activeWorkOrder.job.id}
          onCancel={() => {
            setLoadFromEstimateDialogOpen(false)
          }}
          onSelectEstimate={(selectedEstimate?: Estimate | null) => {
            setActiveWorkOrder((prev) => ({
              ...(prev ?? {}),
              discount: selectedEstimate?.discount
                ? {
                    value: selectedEstimate.discount.value,
                    type: selectedEstimate.discount.type,
                  }
                : undefined,
              description: `${prev?.description ?? ""}\n${selectedEstimate?.description ?? ""}`,
              billingContact: selectedEstimate?.billingContact
                ? {
                    id: selectedEstimate?.billingContact?.id,
                  }
                : undefined,
            }))

            setDescription((prev) => {
              let newDescription = prev?.trim() ?? ""
              if (newDescription.length > 0) {
                newDescription += "\n"
              }
              if (!newDescription.includes(selectedEstimate?.description ?? "")) {
                newDescription += selectedEstimate?.description ?? ""
              }
              return newDescription
            })

            // Merge the incoming line items with the existing line items
            const existingOrgItemIds = lineItems
              .map((li) => li.organizationItem?.id)
              ?.filter((id) => Boolean(id))

            const updatedExistingLineItems = lineItems
              .filter((li) => Boolean(li.organizationItemId) || Boolean(li.organizationItem?.id))
              .map((li: TransactionLineItemFormInput) => {
                const existingLine = selectedEstimate?.lineItems?.find(
                  (eli: EstimateLineItem) => eli.organizationItem?.id === li.organizationItem?.id
                ) as EstimateLineItem | undefined

                return {
                  ...li,
                  quantity: String(
                    existingLine?.quantity || li.quantity
                      ? Math.max(existingLine?.quantity ?? 0, Number(li.quantity ?? 0))
                      : li.quantity
                  ),
                  lineItemDetails: existingLine?.lineItemDetails,
                  showDetails: Boolean(existingLine?.showDetails),
                } as TransactionLineItemFormInput
              })

            const newLines = selectedEstimate?.lineItems
              ?.filter((li) => !existingOrgItemIds.includes(li.organizationItem?.id))
              ?.map((li: EstimateLineItem) => {
                return {
                  id: undefined,
                  number: updatedExistingLineItems.length + li.number,
                  organizationItemId: li.organizationItem?.id,
                  organizationItem: li.organizationItem,
                  lineItemDetails: li.lineItemDetails?.map((lid) => ({
                    id: undefined,
                    number: lid.number,
                    organizationItem: lid.organizationItem,
                    organizationItemId: lid.organizationItem.id,
                    quantity: String(lid.quantity),
                    unitPrice: String(lid.unitPrice),
                  })),
                  showDetails: li.showDetails,
                  description: li.description,
                  quantity: String(li.quantity),
                  unitPrice: String(li.unitPrice),
                  taxRateGroup: li.taxRateGroup,
                  total: li.total,
                  key: nanoid(),
                } as TransactionLineItemFormInput
              }) as TransactionLineItemFormInput[]

            const allLines = updatedExistingLineItems
              .concat(newLines)
              .filter((li) => !isBlank(li.organizationItem?.id))
              .sort((a, b) => {
                return a.number - b.number
              })
              .map((li, idx) => ({
                ...li,
                number: idx + 1,
              }))

            setLineItems(allLines)
            setLoadFromEstimateDialogOpen(false)
          }}
          targetType="workOrder"
        />
      ) : null}
      {changeJobAssignmentDialogOpen && activeWorkOrder?.job ? (
        <ChangeJobAssignmentDialog
          currentSelectedAssignment={assignment}
          filterOutLinkedToWorkOrder
          jobId={activeWorkOrder.job.id}
          onCancel={() => {
            setChangeJobAssignmentDialogOpen(false)
          }}
          onSelectJobAssignment={(selectedAssignment) => {
            setAssignment(selectedAssignment)
            setChangeJobAssignmentDialogOpen(false)
          }}
          user={user}
          workOrderId={activeWorkOrder.id}
        />
      ) : null}
      {saveAsTemplateDialogOpen ? (
        <SaveAsWorkOrderTemplateDialog
          description={description}
          lineItems={lineItems}
          notes={notes}
          onCancel={() => {
            setSaveAsTemplateDialogOpen(false)
          }}
          onSave={(success: boolean, messageKey: string, messageOptions?: any) => {
            if (success) {
              setSnack({ messageKey, messageOptions, variant: "success" })
            } else {
              setSnack({ messageKey, messageOptions, variant: "error" })
            }
            setSaveAsTemplateDialogOpen(false)
          }}
        />
      ) : null}
      {loadFromTemplateDialogOpen ? (
        <LoadWorkOrderFromTemplateDialog
          onCancel={() => {
            setLoadFromTemplateDialogOpen(false)
          }}
          onSelectTemplate={(selectedTemplate) => {
            setDescription(
              description.concat(
                description.trim().length > 0 ? "\n" : "",
                selectedTemplate.description ?? ""
              )
            )
            setNotes(
              notes.concat(notes.trim().length > 0 ? "\n" : "", selectedTemplate.notes ?? "")
            )

            // Merge the incoming line items with the existing line items
            const existingOrgItemIds = lineItems
              .map((li) => li.organizationItem?.id)
              ?.filter((id) => Boolean(id))

            const updatedExistingLineItems = lineItems
              .filter((li) => Boolean(li.organizationItemId) || Boolean(li.organizationItem?.id))
              .map((li) => {
                const existingLine = selectedTemplate.lineItems.find(
                  (tli) => tli.organizationItem?.id === li.organizationItem?.id
                )
                return {
                  ...li,
                  quantity: String(
                    existingLine?.quantity || li.quantity
                      ? Math.max(existingLine?.quantity ?? 0, Number(li.quantity ?? 0))
                      : li.quantity
                  ),
                  lineItemDetails: existingLine?.lineItemDetails,
                  showDetails: Boolean(existingLine?.showDetails),
                }
              }) as TransactionLineItemFormInput[]

            const newLines = selectedTemplate?.lineItems
              ?.filter(
                (li: WorkOrderTemplateLineItem) =>
                  !existingOrgItemIds.includes(li.organizationItem?.id)
              )
              ?.sort((a: WorkOrderTemplateLineItem, b: WorkOrderTemplateLineItem) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              ?.map((li: WorkOrderTemplateLineItem, index: number) => {
                return {
                  id: undefined,
                  number: updatedExistingLineItems.length + index + 1,
                  organizationItemId: li.organizationItem?.id,
                  organizationItem: li.organizationItem,
                  description: li.description ?? li.organizationItem?.description,
                  unitPrice: li.organizationItem.unitSalePrice,
                  quantity: String(li.quantity),
                  lineItemDetails: li.lineItemDetails?.map((lid) => ({
                    id: undefined,
                    number: lid.number,
                    organizationItem: lid.organizationItem,
                    organizationItemId: lid.organizationItem.id,
                    quantity: String(lid.quantity),
                    unitPrice: String(lid.unitPrice),
                  })),
                  showDetails: li.showDetails,
                  taxRateGroup: li.taxRateGroup,
                  key: nanoid(),
                  total: calculateLineTotal(li.quantity, li.unitPrice),
                } as TransactionLineItemFormInput
              }) as TransactionLineItemFormInput[]

            const allLines = updatedExistingLineItems
              .concat(newLines)
              .filter((li) => !isBlank(li.organizationItem?.id))
              .sort((a, b) => {
                return (a.number ?? 0) - (b.number ?? 0)
              })
              .map((li, idx) => ({
                ...li,
                number: idx + 1,
              }))

            setLineItems(allLines)
            setLoadFromTemplateDialogOpen(false)
          }}
        />
      ) : null}
      <Menu
        anchorEl={moreActionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={1}
        onClose={() => {
          setMoreActionsMenuAnchorEl(undefined)
          setMoreActionsMenuAnchorEl(undefined)
        }}
        open={showMoreActionsMenu}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "200px",
          },
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {hasPermissions?.([DefaultPermission.CreateEstimate]) ? (
          <MenuItem disabled={loading} onMouseDown={generateEstimate}>
            {t("component.workOrderDialog.generateEstimate")}
          </MenuItem>
        ) : null}
        {hasPermissions?.([DefaultPermission.CreateInvoice]) ? (
          <MenuItem disabled={loading} onMouseDown={generateInvoice}>
            {t("component.workOrderDialog.generateInvoice")}
          </MenuItem>
        ) : null}
        {hasPermissions?.([DefaultPermission.ReadEstimate]) ? (
          <MenuItem
            disabled={loading}
            onMouseDown={() => {
              setMoreActionsMenuAnchorEl(undefined)
              setLoadFromEstimateDialogOpen(true)
            }}
          >
            {t("component.workOrderDialog.loadFromEstimate")}
          </MenuItem>
        ) : null}
        <MenuItem
          disabled={loading}
          onMouseDown={() => {
            setMoreActionsMenuAnchorEl(undefined)
            setLoadFromTemplateDialogOpen(true)
          }}
        >
          {t("loadFromTemplate")}
        </MenuItem>
        {hasPermissions?.([DefaultPermission.CreateWorkOrderTemplate]) ? (
          <MenuItem
            disabled={loading}
            onClick={() => {
              setMoreActionsMenuAnchorEl(undefined)
              setSaveAsTemplateDialogOpen(true)
            }}
          >
            {t("saveAsTemplate")}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}

const classes = {
  errorContainer: {
    backgroundColor: "#fef2f2",
    padding: "1.25rem",
    border: "1px solid #fee2e2",
    borderRadius: "4px",
    marginBottom: "1.25rem",
  },
  errorTitle: {
    color: "#991b1b",
    fontWeight: "600",
  },
  errorMessage: {
    color: "#7f1d1d",
  },
  formControl: {
    marginTop: "1rem",
  },
  rowContainer: {
    display: "flex",
    marginBottom: "0.625rem",
    marginTop: "0.625rem",
    minWidth: "450px",
  },
  rowItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "50%",
    marginRight: "0.3125rem",
    marginLeft: "0.3125rem",
  },
  staticFieldLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.9rem",
    fontWeight: 600,
    display: "block",
    marginBottom: "0.5rem",
  },
  staticFieldValue: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  lineItemTableContainer: {
    marginTop: "0.625rem",
    paddingBottom: "1.25rem",
  },
  lineItemGridRow: {
    display: "grid",
    gridTemplateColumns: "50px minmax(100px, 3fr) minmax(100px, 3fr) minmax(50px, 1fr) 50px",
    columnGap: "10px",
    boxSizing: "border-box",
    paddingTop: "0.5rem",
    paddingBottom: "0.125rem",
  },
  lineItemHeader: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #cdcdcd",
    "& label": {
      fontSize: "0.8rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
    },
  },
  addLineCell: {
    border: "none",
    marginTop: "1.5rem",
    paddingTop: "0.625rem",
    paddingRight: "1.25rem",
    paddingLeft: "1.25rem",
    textAlign: "center",
  },
  jobAssignmentFieldContainer: {
    display: "flex",
    flexDirection: "row",
  },
  jobAssignmentFieldLabel: {
    minWidth: "100px",
  },
  jobAssignmentFieldValue: {
    flexGrow: 1,
  },
  jobAssignmentLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  changeJobAssignmentLink: {
    fontWeight: "normal",
    marginLeft: "1.875rem",
    textDecoration: "underline",
    color: "inherit",
    cursor: "pointer",
  },
} as const

export default WorkOrderDialog
