/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState, useRef } from "react"
import { useOnClickOutside } from "usehooks-ts"
import { useTranslation } from "react-i18next"
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  closestCenter,
} from "@dnd-kit/core"
import dayjs from "dayjs"
import Box from "@mui/material/Box"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import ResourceView from "./ResourceView"
import JobAssignment from "./JobAssignment"
import ResourceCalendarRow from "./ResourceCalendarRow"
import {
  HOURS,
  DAYS,
  INTERVAL_DURATION,
  INTERVALS_PER_HOUR,
  DAY_MODE_INTERVAL_WIDTH,
  DAY_MODE_ROW_HEIGHT,
  WEEK_MODE_ROW_HEIGHT,
  WEEK_MODE_CELL_WIDTH,
  WEEK_MODE_INTERVAL_HEIGHT,
  DAY_MODE_HEADER_HEIGHT,
  WEEK_MODE_HEADER_HEIGHT,
  WEEK_MODE_RESOURCE_COLUMN_WIDTH,
  DAY_MODE_RESOURCE_COLUMN_WIDTH,
} from "./Constants"
import { calculateAssignmentFrames } from "./utils"
import type { JobAssignment as IJobAssignment, User } from "~/types/apiTypes"
import {
  type JobAssignmentBlockDimensions,
  type JobAssignmentUserBlock,
  type DispatchResource,
  TimeFrameOption,
  JobAssignmentDragDeltas,
} from "~/types/appTypes"
import { createDayJS } from "~/util/dateUtils"
import StaffCell from "./StaffCell"
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { usePreventBackNavigationOnScroll } from "~/hooks/usePreventBackNavigationOnScroll"

function getDateFormat(selectedDate: dayjs.Dayjs, jobAssignment: IJobAssignment) {
  return dayjs(jobAssignment.startDate).isBefore(selectedDate.startOf("day")) ? "lll" : "LT"
}

interface ResourceCalendarProps {
  readonly assignments: IJobAssignment[]
  readonly date: dayjs.Dayjs // the currently selected date
  readonly onAddOrUpdateAssignment: (assignment: IJobAssignment) => void
  readonly onDoubleClickAssignment: (assignment: IJobAssignment) => void
  readonly onReorderResources: (reorderedResources: DispatchResource[]) => void
  readonly resources: DispatchResource[]
  readonly timeFrame: TimeFrameOption
  readonly timeZone: string
}

/**
 * The ResourceCalendar component is meant to have 2 columns.
 * The left column holds the staff list. It should not scroll horizontally. The first
 * row is the header row (with the "Staff" label and/or selector), the time of day labels (or time labels
 * if in week mode). The first row should be sticky - it should not scroll vertically.
 * The right column holds the grid for showing the hours as columns.
 * It is horizontally-scrollable. The header row should show the hours labels,
 * while every other row corresponds to a staff member shown in the left-most column.
 */
const ResourceCalendar = React.forwardRef(
  (
    {
      assignments,
      date,
      onAddOrUpdateAssignment,
      onDoubleClickAssignment,
      onReorderResources,
      resources,
      timeFrame,
      timeZone,
    }: ResourceCalendarProps,
    externalRef
  ) => {
    const { t } = useTranslation()
    const [jobAssignmentUserBlocks, setJobAssignmentUserBlocks] = useState<
      JobAssignmentUserBlock[]
    >(() => {
      return calculateAssignmentFrames(date, assignments, resources, timeFrame, timeZone)
    })
    const [activeJobAssignmentUserBlock, setActiveJobAssignmentUserBlock] =
      useState<JobAssignmentUserBlock | null>()
    const internalRef = useRef(null)

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    )

    usePreventBackNavigationOnScroll(externalRef)

    useOnClickOutside(internalRef, () => {
      console.log("click outside internalRef, so deactivating")
      setActiveJobAssignmentUserBlock(null)
    })

    const timeFormat = t("format:dateFormat.time")
    const gridHeaderHeight =
      timeFrame === TimeFrameOption.DAY ? DAY_MODE_HEADER_HEIGHT : WEEK_MODE_HEADER_HEIGHT

    const dayJsInstance = createDayJS(date, timeZone) ?? dayjs()

    // Scroll to the desired starting hour when this component loads
    useEffect(() => {
      const startHour = 7
      if (timeFrame === TimeFrameOption.DAY) {
        if (externalRef?.current) {
          const startScrollPosX = DAY_MODE_INTERVAL_WIDTH * INTERVALS_PER_HOUR * startHour
          externalRef.current.scrollTo({
            top: 0,
            left: startScrollPosX,
          })
        }
      } else if (timeFrame === TimeFrameOption.WEEK) {
        // const startScrollPosY = WEEK_MODE_INTERVAL_HEIGHT * INTERVALS_PER_HOUR * startHour
        // if (internalRef?.current) {
        //   internalRef.current.scrollBy({
        //     top: startScrollPosY,
        //   })
        // }
      }
    }, [externalRef, timeFrame])

    useEffect(() => {
      setJobAssignmentUserBlocks(
        calculateAssignmentFrames(date, assignments, resources, timeFrame, timeZone)
      )
    }, [assignments, date, resources, timeFrame, timeZone])

    const handleDoubleClickAssignment = useCallback(
      (jobAssignmentUserBlock: JobAssignmentUserBlock) => {
        onDoubleClickAssignment?.(jobAssignmentUserBlock.assignment)
      },
      [onDoubleClickAssignment]
    )

    const handleMouseDownAssignment = useCallback(
      (jobAssignmentUserBlock: JobAssignmentUserBlock) => {
        setActiveJobAssignmentUserBlock(jobAssignmentUserBlock)
      },
      []
    )

    const handleOnDragAssignment = useCallback(
      (
        jobAssignmentUserBlock: JobAssignmentUserBlock,
        dimensions: JobAssignmentBlockDimensions
      ) => {
        // update the "left" property of the frames of the jobAssignmentUserBlocks that are part of the same assignment as the given jobAssignmentUserBlock
        if (timeFrame === TimeFrameOption.DAY) {
          // update the jobAssignmentUserBlocks state by replacing the old blocks with the updated ones
          setJobAssignmentUserBlocks((blocks) => {
            const updatedBlocks = blocks.map((b) => {
              if (
                b.assignment.id === jobAssignmentUserBlock.assignment.id &&
                b.key !== jobAssignmentUserBlock.key
              ) {
                const updatedBlock = { ...b }
                updatedBlock.frame.left = dimensions.x
                return updatedBlock
              } else {
                return b
              }
            })
            return updatedBlocks
          })
        } else if (timeFrame === TimeFrameOption.WEEK) {
          // update the jobAssignmentUserBlocks state by replacing the old blocks with the updated ones
          setJobAssignmentUserBlocks((blocks) => {
            const updatedBlocks = blocks.map((b) => {
              if (
                b.assignment.id === jobAssignmentUserBlock.assignment.id &&
                b.key !== jobAssignmentUserBlock.key
              ) {
                const updatedBlock = { ...b }
                updatedBlock.frame.left += dimensions.deltaX
                return updatedBlock
              } else {
                return b
              }
            })
            return updatedBlocks
          })
        }
      },
      [timeFrame]
    )

    const handleOnDragReset = useCallback(
      (jobAssignmentUserBlock: JobAssignmentUserBlock) => {
        if (timeFrame == TimeFrameOption.WEEK) {
          // This is needed to make sure that ALL the frames for a multi-day assignment are snapped
          // back to the appropriate column's left edge. Otherwise, if the user drags one of them over a bit,
          // they'll all be moved, but then if the user doesn't break the threshold for committing a change,
          // then only the frame that they're dragging will snap back to it's original position.
          setJobAssignmentUserBlocks((blocks) => {
            const updatedBlocks = blocks.map((b) => {
              if (b.assignment.id === jobAssignmentUserBlock.assignment.id) {
                const updatedBlock = { ...b }
                updatedBlock.frame.left = b.frame.originalLeft
                return updatedBlock
              } else {
                return b
              }
            })
            return updatedBlocks
          })
        }
      },
      [timeFrame]
    )

    const handleUpdateAssignmentDayMode = useCallback(
      (
        jobAssignmentUserBlock: JobAssignmentUserBlock,
        dimensions: JobAssignmentBlockDimensions,
        deltas: JobAssignmentDragDeltas
      ) => {
        // this callback will be invoked when the assignment is updated, either via a dialog or drag & drop or whatever.
        const gridRect = internalRef?.current?.getBoundingClientRect()

        // figure out how many minutes the deltaX corresponds to
        const roundedDeltaX =
          Math.round(deltas.x / DAY_MODE_INTERVAL_WIDTH) * DAY_MODE_INTERVAL_WIDTH
        const offsetMinutes = (roundedDeltaX / DAY_MODE_INTERVAL_WIDTH) * INTERVAL_DURATION

        // Similarly, snap the y-coord to the appropriate spot to line up with a row.
        // Make sure it's not negative and doesn't go past the bottom of the grid
        const adjustedY = Math.min(
          Math.max(0, dimensions.y - DAY_MODE_HEADER_HEIGHT),
          gridRect.height - DAY_MODE_ROW_HEIGHT
        )

        const updatedStart = dayjs(jobAssignmentUserBlock.assignment.startDate)
          .tz(timeZone)
          .add(offsetMinutes, "minutes")
          .second(0)

        let updatedEnd = null
        if (dimensions.width != jobAssignmentUserBlock.frame.width) {
          // The user resized the assignment box, adjusting the duration of the assignment.
          const deltaMinutes =
            Math.round(deltas.width / DAY_MODE_INTERVAL_WIDTH) * INTERVAL_DURATION
          updatedEnd = dayjs(jobAssignmentUserBlock.assignment.endDate)
            .tz(timeZone)
            .add(deltaMinutes, "minute")
            .second(0)
        } else {
          // The user dragged the whole assigment to a different time slot. The duration hasn't changed, only the start/end time.
          // So, we just need to calculate the new start & end time for the assignment.
          const originalStart = dayjs(jobAssignmentUserBlock.assignment.startDate).tz(timeZone)
          const originalEnd = dayjs(jobAssignmentUserBlock.assignment.endDate).tz(timeZone)
          let originalDurationMinutes = originalEnd.diff(originalStart, "minute")
          if (originalDurationMinutes < INTERVAL_DURATION) {
            originalDurationMinutes = INTERVAL_DURATION
          }
          updatedEnd = updatedStart.add(originalDurationMinutes, "minute")
        }

        const resourceIdx = Math.round(adjustedY / DAY_MODE_ROW_HEIGHT)
        const newAssignee = resources[resourceIdx] as DispatchResource
        const oldAssignee = jobAssignmentUserBlock.assignee
        if (newAssignee.id !== oldAssignee.id) {
          const assignees = jobAssignmentUserBlock.assignment.assignees.filter(
            (a) => a.id !== oldAssignee.id
          )
          jobAssignmentUserBlock.assignment.assignees = [
            ...assignees,
            { id: newAssignee.id } as User,
          ]
        }
        jobAssignmentUserBlock.assignment.startDate = updatedStart.utc().format()
        jobAssignmentUserBlock.assignment.endDate = updatedEnd.utc().format()
        onAddOrUpdateAssignment(jobAssignmentUserBlock.assignment)
      },
      [timeZone, resources, onAddOrUpdateAssignment]
    )

    const handleUpdateAssignmentWeekMode = useCallback(
      (
        jobAssignmentUserBlock: JobAssignmentUserBlock,
        dimensions: JobAssignmentBlockDimensions,
        dragDeltas: JobAssignmentDragDeltas
      ) => {
        const assignment = jobAssignmentUserBlock.assignment

        const allBlocks = jobAssignmentUserBlocks
          .filter((b) => b.assignment.id === assignment.id)
          .slice()
          .sort((a, b) => a.frame.left - b.frame.left)
        const lastBlock = allBlocks[allBlocks.length - 1]

        if (dimensions.height !== lastBlock.frame.height && dragDeltas.height !== 0) {
          // The user is resizing the assignment by dragging the bottom edge up or down; we just need to adjust the end date accordingly
          const minutesDelta =
            Math.round(dragDeltas.height / WEEK_MODE_INTERVAL_HEIGHT) * WEEK_MODE_INTERVAL_HEIGHT
          onAddOrUpdateAssignment({
            ...assignment,
            endDate: dayjs(assignment.endDate).add(minutesDelta, "minute").utc().format(),
          })
        } else {
          // Make sure the assignment's left edge snaps to the appropriate column's left edge
          // Note - dayOfWeekIndex should be a number in the range [0, 6]  (i.e., a day of week)
          const xShiftDays = Math.round(dragDeltas.x / WEEK_MODE_CELL_WIDTH)

          const timeOfDayAdjustment =
            Math.round(dragDeltas.y / WEEK_MODE_INTERVAL_HEIGHT) * WEEK_MODE_INTERVAL_HEIGHT

          const start = dayjs(jobAssignmentUserBlock.assignment.startDate)
            .tz(timeZone)
            .add(xShiftDays, "days")
            .add(timeOfDayAdjustment, "minutes") // dayjs(newDay).hour(0).second(0).set("minute", startMinutes)
          const durationMinutes = dayjs(assignment.endDate).diff(
            dayjs(assignment.startDate),
            "minute"
          )
          const end = start.add(durationMinutes, "minute")

          const updatedAssignment = {
            ...jobAssignmentUserBlock.assignment,
            startDate: start.utc().format(),
            endDate: end.utc().format(),
          }
          onAddOrUpdateAssignment(updatedAssignment)
        }
      },
      [jobAssignmentUserBlocks, onAddOrUpdateAssignment, timeZone]
    )

    function handleDragEnd(event: DragEndEvent) {
      const { active, over } = event

      // dropped outside the list
      if (!over) {
        return
      }

      if (active.id === over.id) {
        return
      }

      const oldIndex = resources.findIndex((r) => r.id === active.id)
      const newIndex = resources.findIndex((r) => r.id === over.id)

      const sorted = arrayMove(resources, oldIndex, newIndex).map((item, idx) => ({
        ...item,
        position: idx,
      }))

      onReorderResources(sorted)
    }

    const resourceColumnWidth =
      timeFrame === TimeFrameOption.DAY
        ? DAY_MODE_RESOURCE_COLUMN_WIDTH
        : WEEK_MODE_RESOURCE_COLUMN_WIDTH

    return (
      <Box
        onMouseDown={(event) => {
          if (!(event.target as Element).closest(".job-assignment")) {
            // ignore clicks on the job assignment itself
            setActiveJobAssignmentUserBlock(null)
          }
        }}
        ref={externalRef}
        sx={{
          position: "relative",
          boxSizing: "border-box",
          flexGrow: 1,
          display: "grid",
          paddingBottom: `${DAY_MODE_HEADER_HEIGHT}px`,
          fontSize: "0.875rem",
          overflow: "scroll", // this is needed in order for the programmatic scrolling to work
          height: "calc(100vh - 256px)", // without this, the sticky header won't work!
          alignContent: "start", // without this, the resource rows will be floating in the middle of the div
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            top: 0,
            zIndex: 10,
            backgroundColor: headerBackgroundColor,
            borderBottom: "1px solid #dfdfdf",
            height: gridHeaderHeight,
            minHeight: gridHeaderHeight,
            maxHeight: gridHeaderHeight,
            position: "sticky",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              left: 0,
              top: 0,
              zIndex: 12,
              background: "#ededed",
              display: "flex",
              alignItems: "center",
              height: "100%",
              borderRight: "1px solid #dfdfdf",
              width: resourceColumnWidth,
              minWidth: resourceColumnWidth,
              maxWidth: resourceColumnWidth,
            }}
          >
            <span
              style={{
                marginLeft: 10,
              }}
            >
              {timeFrame == TimeFrameOption.DAY
                ? t("component.dispatchCalendar.resourceLabel")
                : ""}
            </span>
          </Box>
          {timeFrame == TimeFrameOption.DAY &&
            HOURS.map((h) => {
              return (
                <Box
                  key={h}
                  sx={{
                    backgroundColor: headerBackgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "1px solid #dfdfdf",
                    boxSizing: "border-box",
                    width: INTERVALS_PER_HOUR * DAY_MODE_INTERVAL_WIDTH,
                    minWidth: INTERVALS_PER_HOUR * DAY_MODE_INTERVAL_WIDTH,
                  }}
                >
                  <span>{dayJsInstance.hour(h).minute(0).format(timeFormat)}</span>
                </Box>
              )
            })}
          {timeFrame == TimeFrameOption.WEEK && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                boxSizing: "border-box",
                textAlign: "center",
                height: gridHeaderHeight,
                minHeight: gridHeaderHeight,
                maxHeight: gridHeaderHeight,
              }}
            >
              {DAYS.map((d) => {
                const day = dayJsInstance.day(d)
                return (
                  <Box
                    key={d}
                    sx={{
                      backgroundColor: headerBackgroundColor,
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRight: "1px solid #dfdfdf",
                      boxSizing: "border-box",
                      lineHeight: 1,
                      width: WEEK_MODE_CELL_WIDTH,
                      maxWidth: WEEK_MODE_CELL_WIDTH,
                      minWidth: WEEK_MODE_CELL_WIDTH,
                    }}
                  >
                    <Box
                      sx={
                        day.isSame(dayjs(), "day")
                          ? {
                              fontWeight: 800,
                              width: 40,
                              height: 40,
                              borderRadius: 20,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                          : {}
                      }
                    >
                      <Box sx={{ fontWeight: "normal" }}>{day.format("ddd")}</Box>
                      <Box>{day.format("D")}</Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
        <Box className="gridCoordinateParent" ref={internalRef}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              id="resourceColumn"
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                position: "sticky",
                left: 0,
                zIndex: 9,
                background: "white",
                width: resourceColumnWidth,
                maxWidth: resourceColumnWidth,
                minWidth: resourceColumnWidth,
              }}
            >
              {timeFrame == TimeFrameOption.DAY ? (
                <DndContext
                  autoScroll={false}
                  collisionDetection={closestCenter}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  onDragEnd={handleDragEnd}
                  sensors={sensors}
                >
                  <SortableContext items={resources} strategy={verticalListSortingStrategy}>
                    {resources.map((s) => (
                      <StaffCell
                        key={s.id}
                        resource={s}
                        style={{
                          ...classes.rowLabelCell,
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          gap: "0.5rem",
                          alignItems: "center",
                          paddingLeft: "0.25rem",
                        }}
                      >
                        <DragIndicatorIcon
                          color="action"
                          sx={{ fontSize: "1rem", touchAction: "none" }}
                        />
                        <ResourceView resource={s} />
                      </StaffCell>
                    ))}
                  </SortableContext>
                </DndContext>
              ) : timeFrame == TimeFrameOption.WEEK ? (
                HOURS.map((h) => {
                  return (
                    <Box
                      key={h}
                      sx={{
                        ...classes.rowLabelCell,
                        height: WEEK_MODE_ROW_HEIGHT,
                        minHeight: WEEK_MODE_ROW_HEIGHT,
                        maxHeight: WEEK_MODE_ROW_HEIGHT,
                        justifyContent: "flex-start",
                      }}
                    >
                      <span>{dayJsInstance.hour(h).minute(0).format(timeFormat)}</span>
                    </Box>
                  )
                })
              ) : null}
            </Box>
            <Box sx={{ overflow: "auto" }}>
              {timeFrame == TimeFrameOption.DAY &&
                resources.map((r) => {
                  return (
                    <ResourceCalendarRow
                      height={DAY_MODE_ROW_HEIGHT}
                      intervalDuration={INTERVAL_DURATION}
                      intervalLength={DAY_MODE_INTERVAL_WIDTH}
                      key={r.id}
                      mode={TimeFrameOption.DAY}
                    />
                  )
                })}
              {timeFrame == TimeFrameOption.WEEK &&
                HOURS.map((h) => {
                  return (
                    <ResourceCalendarRow
                      height={WEEK_MODE_ROW_HEIGHT}
                      intervalDuration={60}
                      intervalLength={15}
                      key={h}
                      mode={TimeFrameOption.WEEK}
                    />
                  )
                })}
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {jobAssignmentUserBlocks?.map((block: JobAssignmentUserBlock) => {
              return (
                <JobAssignment
                  dateFormat={getDateFormat(date, block.assignment)}
                  intervalLength={
                    timeFrame === TimeFrameOption.DAY
                      ? DAY_MODE_INTERVAL_WIDTH
                      : WEEK_MODE_INTERVAL_HEIGHT
                  }
                  isAssignmentSelected={
                    block.assignment.id === activeJobAssignmentUserBlock?.assignment.id
                  }
                  jobAssignmentUserBlock={block}
                  key={block.key}
                  onDoubleClick={handleDoubleClickAssignment}
                  onDrag={handleOnDragAssignment}
                  onDragReset={handleOnDragReset}
                  onMouseDown={handleMouseDownAssignment}
                  onUpdate={
                    timeFrame === TimeFrameOption.DAY
                      ? handleUpdateAssignmentDayMode
                      : handleUpdateAssignmentWeekMode
                  }
                  timeFrame={timeFrame}
                  timeZone={timeZone}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    )
  }
)
const headerBackgroundColor = "#ededed"

const classes = {
  rowLabelCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    borderBottom: "2px solid #EFEFEF",
    borderRight: "1px solid #E4E4E4",
    boxSizing: "border-box",
    paddingLeft: "10px",
    fontWeight: 600,
    backgroundColor: "#fff",
    opacity: 1,
  },
}

export default ResourceCalendar
