/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useMemo } from "react"
import * as Sentry from "@sentry/react"
import { useMutation } from "@apollo/client"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

import { EDIT_JOB } from "~/queries/editJob"
import { GET_JOB_BASIC_INFO } from "~/queries/getJobBasicInfo"
import { SEND_JOB_EMAIL } from "~/queries/sendJobEmail"
import JobBasicInfoTab from "./components/JobBasicInfoTab"
import JobFileTab from "./components/JobFileTab"
import JobFormTabBar from "./components/JobFormTabBar"
import JobInvoicesTab from "./components/JobInvoicesTab"
import JobEstimateTab from "./components/JobEstimateTab"
import JobWorkOrderTab from "./components/JobWorkOrderTab"
import JobChecklistTab from "./components/JobChecklistTab"
import JobTasksTab from "./components/JobTasksTab"
import JobAssignmentsTab from "./components/JobAssignmentsTab"
import JobLogTab from "./components/JobLogTab"
import ConfirmationDialog from "~/components/ConfirmationDialog"
import MainLayout from "~/components/MainLayout"
import PageHeader from "~/components/PageHeader"
import Seo from "~/components/Seo"
import SnackbarMessage from "~/components/SnackbarMessage"
import { parseGraphQLErrorCode, JOBS } from "~/util"
import { useAuth } from "~/context/AuthContext"
import { DefaultPermission, Snack } from "~/types"
import EmailDialog, { EmailDialogMode } from "~/components/EmailDialog"

const ALL_TABS = [
  "basic",
  "files",
  "estimates",
  "assignments",
  "work_orders",
  "tasks",
  "checklist",
  "invoices",
  "log",
]

function EditJob() {
  const { id, activeTab } = useParams()
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, hasPermissions } = useAuth()
  const [snack, setSnack] = useState<Snack>()
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [notifyCustomerPromptIsOpen, setNotifyCustomerPromptIsOpen] = useState<boolean>(false)
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false)
  const TABS = useMemo(
    () =>
      ALL_TABS.map((t) => {
        if (t === "estimates" && !hasPermissions?.([DefaultPermission.ReadEstimate])) {
          return null
        } else if (t === "invoices" && !hasPermissions?.([DefaultPermission.ReadInvoice])) {
          return null
        } else if (t === "checklist" && !hasPermissions?.([DefaultPermission.ReadChecklist])) {
          return null
        } else {
          return t
        }
      }).filter((t) => t !== null),
    [hasPermissions]
  )

  const [editJob, { loading: editJobLoading }] = useMutation(EDIT_JOB, {
    onCompleted: (data) => {
      const { editJob } = data
      if (editJob?.events?.map((e) => e.id)?.includes("PROMPT_CUSTOMER_NOTIFICATION")) {
        setNotifyCustomerPromptIsOpen(true)
      }
      setSnack({
        messageKey: "messages.changesSaved",
        variant: "success",
      })
      setInEditMode(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      const errorCode = parseGraphQLErrorCode(error)
      setSnack({ messageKey: errorCode, variant: "error" })
    },
  })

  const [sendJobEmail, { loading: sendJobEmailLoading }] = useMutation(SEND_JOB_EMAIL, {
    onCompleted: () => {
      setIsEmailDialogOpen(false)
      setSnack({ messageKey: "messages.messageSent", variant: "success" })
    },
    onError: (error) => {
      Sentry.captureException(error)
      setSnack({ messageKey: "messages.messageFailed", variant: "error" })
    },
  })

  const {
    data,
    error,
    loading: getJobByIdLoading,
    refetch,
  } = useQuery(GET_JOB_BASIC_INFO, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    setSnack(location?.state?.snack)
  }, [location?.state?.snack])

  const job = data?.getJobById
  const jobNumber = job ? `#${job.number}` : ""

  const getActiveTabIndex = () => {
    return Math.max(TABS.indexOf(activeTab ?? TABS[0]), 0)
  }

  function renderTabContent() {
    switch (activeTab) {
      case "basic":
      case null:
      case undefined:
      case "":
        return (
          <JobBasicInfoTab
            inEditMode={inEditMode}
            job={job}
            loading={editJobLoading}
            onCancel={() => {
              setInEditMode(false)
            }}
            onEnterEditMode={() => {
              setInEditMode(true)
            }}
            onSave={(payload) => {
              editJob(payload)
            }}
          />
        )
      case "files":
        return <JobFileTab job={job} />
      case "estimates":
        return <JobEstimateTab job={job} />
      case "assignments":
        return <JobAssignmentsTab job={job} />
      case "work_orders":
        return <JobWorkOrderTab job={job} />
      case "tasks":
        return <JobTasksTab job={job} />
      case "checklist":
        return <JobChecklistTab job={job} />
      case "invoices":
        return <JobInvoicesTab job={job} />
      case "log":
        return <JobLogTab job={job} />
      default:
        return <Box>invalid tab</Box>
    }
  }

  if (error) {
    Sentry.captureException(error)
  }

  return (
    <>
      <Seo title={t(JOBS.titleKey)} />
      {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
      <MainLayout activeSection={JOBS}>
        <Box
          sx={{
            margin: "0 1.25rem",
          }}
        >
          <PageHeader
            breadcrumbs={[{ to: JOBS.path, titleKey: JOBS.titleKey }]}
            icon={JOBS.icon}
            leafTitle={`${t("editJob")} ${jobNumber}`}
          />
          <ConfirmationDialog
            description={t("component.notifyCustomerDialog.message")}
            id="notify-customer-dialog"
            isLoading={false}
            onCancel={() => setNotifyCustomerPromptIsOpen(false)}
            onConfirm={() => {
              setIsEmailDialogOpen(true)
              setNotifyCustomerPromptIsOpen(false)
            }}
            open={notifyCustomerPromptIsOpen}
            title={t("component.notifyCustomerDialog.title")}
          />
          {isEmailDialogOpen ? (
            <EmailDialog
              isJobEditable={false}
              job={job}
              mode={EmailDialogMode.COMPOSE_NEW}
              onCancel={() => setIsEmailDialogOpen(false)}
              onSend={(payload) => {
                sendJobEmail({ variables: payload })
              }}
              sending={sendJobEmailLoading}
            />
          ) : null}
          {error ? (
            <Alert
              severity="error"
              sx={{
                margin: "10% auto",
                maxWidth: "900px",
                fontSize: "1rem",
                alignItems: "center",
              }}
              variant="filled"
            >
              {t(parseGraphQLErrorCode(error))}
            </Alert>
          ) : null}
          {getJobByIdLoading ? (
            <Box
              sx={{
                padding: "6.25rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="secondary" size={40} thickness={6.0} />
            </Box>
          ) : null}
          {!getJobByIdLoading && job ? (
            <>
              <JobFormTabBar
                activeTabIndex={getActiveTabIndex()}
                job={job}
                mode="edit"
                onTabChange={(idx) => {
                  const tab = TABS[idx]
                  navigate(`/app/jobs/edit/${id}/${tab}`)
                }}
                user={user}
              />
              {renderTabContent()}
            </>
          ) : null}
        </Box>
      </MainLayout>
    </>
  )
}

export default EditJob
